<template>
  <c-box
    id="kuisioner"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <Steps
        :current="1"
        :total="6"
      />

      <c-box
        w="100%"
        max-width="590px"
        mx="auto"
        :margin-top="['16px', '24px']"
      >
        <c-box text-align="center">
          <c-text
            color="primary.400"
            font-family="roboto"
            text-transform="uppercase"
            font-weight="500"
            :font-size="['16px', '18px']"
            margin-bottom="10px"
          >
            Formulir Gizi (1/6)
          </c-text>
          <c-heading
            font-weight="700"
            :font-size="['18px', '28px']"
            :line-height="['27px', '42px']"
            color="black.900"
          >
            Identitas &amp; Data Umum
          </c-heading>
        </c-box>
        
        <form
          v-chakra
          marginTop="24px"
          @submit.prevent="submit"
        >
          <FormAccordionList allow-toggle>
            <FormAccordionListItem
              label="Data Diri"
              :is-error="!isSectionDataDiriValid"
            >
              <FormInput
                v-model="firstName"
                type="text"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.firstName.$invalid"
                label="Nama Depan"
                placeholder="Masukkan nama depan"
              />

              <FormInput
                v-model="middleName"
                type="text"
                :is-disabled="!isEditable"
                label="Nama Tengah"
                placeholder="Masukkan nama tengah"
              />

              <FormInput
                v-model="lastName"
                type="text"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.lastName.$invalid"
                label="Nama Belakang"
                placeholder="Masukkan nama belakang"
              />

              <FormSelect
                v-model="gender"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.gender.$invalid"
                label="Jenis Kelamin"
                placeholder="Pilih Jenis Kelamin"
                :options="[
                  {
                    value: 'male',
                    label: 'Laki-laki',
                  },
                  {
                    value: 'female',
                    label: 'Perempuan',
                  }
                ]"
              />

              <FormInput
                type="number"
                :value="age"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.age.$invalid"
                label="Usia"
                placeholder="Masukkan usia"
                is-read-only
              />

              <FormInput
                v-model="birthDate"
                type="date"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.birthDate.$invalid"
                label="Tanggal Lahir"
                placeholder="Masukkan tanggal lahir"
              />
            </FormAccordionListItem>
            <FormAccordionListItem
              label="Alamat"
              :is-error="!isSectionAlamatValid"
            >
              <FormInput
                v-model="address"
                type="text"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.address.$invalid"
                label="Alamat Rumah"
                placeholder="Masukkan alamat"
              />

              <FormSelect
                v-model="country"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.country.$invalid"
                label="Negara"
                placeholder="Pilih Negara"
                :options="countries"
                @trigger-change="handleChangeCountry"
              />

              <FormSelect
                v-model="province"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.province.$invalid"
                label="Provinsi"
                placeholder="Pilih Provinsi"
                :options="states"
                @trigger-change="handleChangeProvince"
              />

              <FormSelect
                v-model="city"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.city.$invalid"
                label="Kota"
                placeholder="Pilih Kota"
                :options="cities"
              />

              <FormInput
                v-model="zipCode"
                type="number"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.zipCode.$invalid"
                label="Kode Pos"
                placeholder="Masukkan Kode Pos"
              />

              <FormInput
                v-model="phone"
                type="tel"
                :is-disabled="!isEditable"
                is-required
                :is-invalid="$v.phone.$invalid"
                label="Nomor HP (available whatsapp)"
                placeholder="Masukkan nomor telepon"
              />
            </FormAccordionListItem>
          </FormAccordionList>

          <FormSelect
            v-model="mainTarget"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.mainTarget.$invalid"
            label="Apa tujuan utama kamu?"
            placeholder="Pilih Jawaban"
            :options="OPTIONS_MAIN_TARGET"
          />

          <FormSelect
            v-model="expectedDiet"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.expectedDiet.$invalid"
            label="Tipikal diet yang kamu harapkan"
            placeholder="Pilih Jawaban"
            :options="OPTIONS_EXPECTED_DIET"
          />

          <FormSelect
            v-model="hasScales"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.hasScales.$invalid"
            label="Apakah kamu memiliki timbangan berat badan di rumah?"
            placeholder="Pilih Jawaban"
            :options="OPTIONS_BOOLEAN"
          />

          <FormSelect
            v-model="currentCondition"
            :is-disabled="!isEditable"
            is-required
            :is-invalid="$v.currentCondition.$invalid"
            label="Mana yang paling mendeskripsikan kondisi Klien saat ini?"
            placeholder="Pilih Jawaban"
            :options="OPTIONS_CURRENT_CONDITION"
          />

          <c-flex
            margin-top="16px"
            justify-content="center"
          >
            <c-button
              type="submit"
              w="100%"
              h="48px"
              max-w="500px"
              border-radius="100px"
              variant-color="primary"
              variant="solid"
              loading-text="Submitting"
              :is-disabled="$v.$invalid"
              :is-loading="isSubmitting"
            >
              Selanjutnya
            </c-button>
          </c-flex>
        </form>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import Steps from '@/components/steps'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select'
import FormInput from '@/views/client/kuisioner/forms/form-input'
import {
  OPTIONS_BOOLEAN,
  OPTIONS_CURRENT_CONDITION,
  OPTIONS_EXPECTED_DIET,
  OPTIONS_MAIN_TARGET,
} from '@/constants/questionnaire'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'

export default {
  name: 'FormulirGizi1Page',
  components: {
    FormAccordionListItem,
    FormAccordionList,
    BreadcrumbPath,
    Steps,
    FormSelect,
    FormInput,
  },
  data() {
    return {
      OPTIONS_MAIN_TARGET,
      OPTIONS_EXPECTED_DIET,
      OPTIONS_BOOLEAN,
      OPTIONS_CURRENT_CONDITION,
      firstName: null,
      middleName: null,
      lastName: null,
      gender: null,
      // age: null,
      birthDate: null,
      address: null,
      city: null,
      province: null,
      country: null,
      zipCode: null,
      phone: null,
      mainTarget: null,
      expectedDiet: null,
      hasScales: null,
      currentCondition: null,
      isSubmitting: false,
      // ----
      countries: [],
      states: [],
      cities: [],
      questions: [],
    }
  },
  validations: {
    firstName: { required },
    // middleName: { required },
    lastName: { required },
    gender: { required },
    age: { required, numeric },
    birthDate: { required },
    address: { required },
    city: { required },
    province: { required },
    country: { required },
    zipCode: { required, numeric },
    phone: { required, numeric },
    mainTarget: { required },
    expectedDiet: { required },
    hasScales: { required },
    currentCondition: { required },
  },
  computed: {
    ...mapState({
      profile: (s) => s.profile.dataProfile,
      kuisData: (s) => s.kuisioner.generalData,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    ...mapGetters({
      axios: 'axios',
    }),
    programId() {
      return this.$route.params.programId
    },
    isSectionDataDiriValid() {
      return !this.$v.firstName.$invalid
            // && !this.$v.middleName.$invalid
            && !this.$v.lastName.$invalid
            && !this.$v.gender.$invalid
            && !this.$v.age.$invalid
            && !this.$v.birthDate.$invalid
    },
    isSectionAlamatValid() {
      return !this.$v.address.$invalid
            && !this.$v.city.$invalid
            && !this.$v.province.$invalid
            && !this.$v.country.$invalid
            && !this.$v.zipCode.$invalid
            && !this.$v.phone.$invalid
    },
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    selectedCountry() {
      return this.countries.find((it) => it.value === this.country)
    },
    selectedState() {
      return this.states.find((it) => it.value === this.province)
    },
    selectedCity() {
      return this.cities.find((it) => it.value === this.city)
    },
    value() {
      return {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        gender: this.gender,
        age: this.age,
        birthDate: this.birthDate,
        address: this.address,
        city: this.city,
        province: this.province,
        country: this.country,
        zipCode: this.zipCode,
        phone: this.phone,
        mainTarget: this.mainTarget,
        expectedDiet: this.expectedDiet,
        hasScales: this.hasScales,
        currentCondition: this.currentCondition,
      }
    },
    age: {
      get() {
        return new Date().getFullYear() - new Date(this.birthDate).getFullYear()
      },
      set() {
        // console.log(val)
      }, 
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    profile: {
      immediate: true,
      handler(profile) {
        if (profile == null) return

        this.firstName = this.firstName ?? profile.firstName
        this.middleName = this.middleName ?? profile.middleName
        this.lastName = this.lastName ?? profile.lastName
        this.birthDate = this.birthDate ?? profile.birthDate
        this.gender = this.gender ?? profile.gender
        this.address = this.address ?? profile.address
        this.phone = this.phone ?? profile.phone
        this.country = this.country ?? profile.country
        this.province = this.province ?? profile.province
        this.city = this.city ?? profile.city
        this.zipCode = this.zipCode ?? profile.zipCode
      },
    },
    kuisData: {
      immediate: true,
      handler(kuisData) {
        if (kuisData == null) return

        this.firstName = kuisData.firstName
        this.middleName = kuisData.middleName
        this.lastName = kuisData.lastName
        this.age = kuisData.age
        this.birthDate = kuisData.birthDate
        this.gender = kuisData.gender
        this.address = kuisData.address
        this.phone = kuisData.phone
        this.country = kuisData.country
        this.province = kuisData.province
        this.city = kuisData.city
        this.zipCode = kuisData.zipCode
        this.hasScales = kuisData.hasScales
        this.mainTarget = kuisData.mainTarget
        this.expectedDiet = kuisData.expectedDiet
        this.currentCondition = kuisData.currentCondition
      },
    },
    selectedCountry: {
      async handler(country) {
        this.states = []
        this.cities = []
        await this.getStates(country.id)
      },
    },
    selectedState: {
      async handler(state) {
        this.cities = []
        await this.getCities(state.id)
      },
    },
    $data: {
      handler: _.debounce(function() {
        this.setStoreGeneralData(this.value)
      }, 1000),
      deep: true,
    },
  },
  created() {
    if (this.currentStep && this.currentStep != 1) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    }
  },
  async mounted() {
    await this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'generalData',
    })

    await Promise.all([
      await this.getCountries(),
      await this.$store.dispatch('profile/getProfileClient'),
    ])
  },
  methods: {
    ...mapMutations({
      setStoreGeneralData: 'kuisioner/setGeneralData',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    handleChangeCountry() {
      this.province = ''
      this.city = ''
    },
    handleChangeProvince() {
      this.city = ''
    },
    async submit() {
      try {
        this.isSubmitting = true
        await this.$store.dispatch('kuisioner/setGeneralData', {
          programId: this.programId,
          answers: this.answers,
          value: this.value,
          step: 2,
        })
        await this.$store.dispatch('kuisioner/updateCurrentStep', 2)
        await this.$store.commit('kuisioner/resetGeneralData')
        await this.$router.push({
          name: 'client.kuisioner2',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
      } finally {
        this.isSubmitting = false
      }
    },
    async getCities(stateId) {
      if (stateId == null) return

      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data)
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getStates(countryId) {
      if (countryId == null) return

      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data)
      this.states = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getCountries() {
      let { data } = await this.axios
        .get('/v1/general/countries')
        .then((it) => it.data)
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
  },
}
</script>
